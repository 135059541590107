import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import AdminPage from '../../../../../components/adminPage';
import { getResourceText } from '../../../../../lib/language';
import { LoadingSpinnerButton } from '../../../../../components/loading';
import { useApi } from '../../../../../hooks/api';
import LabelInput from '../../../../../components/labelInput';
import LabelSelect from '../../../../../components/labelSelect';
import { googleDocsFormat } from '../../../../../lib/url';

const AdminCreateDownloadable = ({ resourcePackId: resourceId, location }) => {
  const [downloadableName, setDownloadableName] = useState('');
  const [downloadableURL, setDownloadableURL] = useState('');
  const [resourcePack, setResourcePack] = useState();
  const [downloadableType, setDownloadableType] = useState();
  const [downloadableNameError, setDownloadableNameError] = useState(false);
  const [downloadableURLError, setDownloadableURLError] = useState(false);
  const [downloadableURLFormatError, setDownloadableURLFormatError] = useState(false);
  const [downloadableTypeError, setDownloadableTypeError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);

  const getSearchParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      sectionId: params.get('sectionId'),
      weekId: params.get('weekId')
    };
  };

  const { admin: { createDownloadable: apiCreateDownloadable, getResourcePack } } = useApi();

  useEffect(() => {
    if (resourceId) {
      const getData = async () => {
        const { status, json } = await getResourcePack(resourceId);

        if (status === 200) {
          setResourcePack(json);
        } else {
          setError(true);
        }
      };

      getData().catch(() => setError(true));
    }
  }, [getResourcePack, resourceId]);

  const validate = () => {
    if (!downloadableName) {
      setDownloadableNameError(true);
    } else {
      setDownloadableNameError(false);
    }

    if (!downloadableURL) {
      setDownloadableURLError(true);
    } else {
      setDownloadableURLError(false);
    }

    if (downloadableURL && !googleDocsFormat.exec(downloadableURL)) {
      setDownloadableURLFormatError(true);
    } else {
      setDownloadableURLFormatError(false);
    }

    if (!downloadableType) {
      setDownloadableTypeError(true);
    } else {
      setDownloadableTypeError(false);
    }
  };

  const isValid = () => {
    if (!!downloadableName?.trim() && !!downloadableURL?.trim() && !!downloadableType) {
      return !(downloadableURL && !googleDocsFormat.exec(downloadableURL));
    }
    return false;
  };

  const generateAddDownloadableUrl = () => {
    const { sectionId, weekId } = getSearchParams();
    let url = `/admin/resource-packs/${resourceId}`;

    if (sectionId) {
      url = `${url}/sections/${sectionId}`;
    }

    if (weekId) {
      url = `${url}/weeks/${weekId}`;
    }

    return `${url}/downloadables/add`;
  };

  const createDownloadable = async (event) => {
    event.preventDefault();
    setApiError(false);
    validate();

    if (isValid()) {
      const formattedDownloadableUrl = downloadableURL ? googleDocsFormat.exec(downloadableURL)[0] : downloadableURL;

      setLoading(true);

      const { status } = await apiCreateDownloadable({ resourceId, downloadableName, downloadableURL: formattedDownloadableUrl, downloadableType });

      if (status === 201) {
        await navigate(generateAddDownloadableUrl());
      } else {
        setApiError(true);
      }

      setLoading(false);
    }
    return false;
  };
  return (
    <AdminPage withBack>
      <h2 className="admin-create-downloadable-heading">{resourcePack?.resourceName}</h2>
      <div className="admin-create-downloadable-container">
        <h2 className="admin-create-downloadable-container__heading">
          <span className="admin-create-downloadable-container__heading-text">
            {getResourceText('createDownloadable')}
          </span>
          {loading ? <LoadingSpinnerButton/> : null}
        </h2>
        <div className="admin-create-downloadable-container__body-container">
          <form onSubmit={(event) => createDownloadable(event)}>
            <LabelInput value={downloadableName} onChange={setDownloadableName} error={downloadableNameError} errorCode="downloadableNameError" labelKey="name"
                        classNamePrefix="admin-create-downloadable-container"/>
            <LabelInput value={downloadableURL} onChange={setDownloadableURL} error={downloadableURLError || downloadableURLFormatError}
                        errorCode={downloadableURLFormatError ? 'downloadableURLFormatError' : 'downloadableURLError'} labelKey="downloadableURL"
                        classNamePrefix="admin-create-downloadable-container"/>
            <LabelSelect labelKey="resourceHTMLType" error={downloadableTypeError}
                         options={[{ label: 'Please select', value: '' }, { label: 'PDF', value: 'pdf' }, { label: 'Image', value: 'image' }]} value={downloadableType} onChange={setDownloadableType}
                         classNamePrefix="admin-create-downloadable-container"/>
            <div className="admin-create-downloadable-container__actions">
              {apiError ? <span
                className="admin-create-downloadable-container__action-error">{getResourceText('createDownloadableApiError')}</span> : null}
              <button type="button" className="btn admin-btn admin-create-downloadable-container__action"
                      onClick={async () => await navigate(`/admin/resource-packs/${resourceId}/edit`)}>{getResourceText('cancel')}</button>
              <button type="submit"
                      className="btn admin-btn admin-create-lesson-container__action">{getResourceText('createDownloadable')}</button>
            </div>
          </form>
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminCreateDownloadable;