import React, { useState } from 'react';
import { navigate } from 'gatsby';
import AdminPage from '../../../components/adminPage';
import { getResourceText } from '../../../lib/language';
import { LoadingSpinnerButton } from '../../../components/loading';
import { useApi } from '../../../hooks/api';
import LabelCheckboxInput from '../../../components/labelCheckboxInput';
import LabelInput from '../../../components/labelInput';

const AdminCreateOrganisation = () => {
  const [organisationName, setOrganisationName] = useState('');
  const [organisationNameError, setOrganisationNameError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isActive, setIsActive] = useState();

  const { admin: { createOrganisation: apiCreateOrganisation } } = useApi();

  const validate = () => {
    if (!organisationName) {
      setOrganisationNameError(true);
    } else {
      setOrganisationNameError(false);
    }
  };

  const isValid = () => !!organisationName?.trim();

  const createOrganisation = async (event) => {
    event.preventDefault();
    setApiError(false);
    validate();

    if (isValid()) {
      setLoading(true);

      const { status } = await apiCreateOrganisation({ organisationName, isActive });

      if (status === 201) {
        await navigate('/admin/organisations');
      } else {
        setApiError(true);
      }

      setLoading(false);
    }
    return false;
  };
  return (
    <AdminPage withBack>
      <div className="admin-create-organisation-container">
        <h2 className="admin-create-organisation-container__heading">
          <span className="admin-create-organisation-container__heading-text">
            {getResourceText('createOrganisation')}
          </span>
          {loading ? <LoadingSpinnerButton/> : null}
        </h2>
        <div className="admin-create-organisation-container__body-container">
          <form onSubmit={(event) => createOrganisation(event)}>
            <LabelInput value={organisationName} onChange={(update) => setOrganisationName(update)} labelKey="name" error={organisationNameError} classNamePrefix="admin-create-organisation-container" />
            <LabelCheckboxInput labelKey="isActive" checked={isActive} onChange={(val) => setIsActive(val)} classNamePrefix="admin-create-organisation-container"/>
            <div className="admin-create-organisation-container__actions">
              {apiError ? <span
                className="admin-create-organisation-container__action-error">{getResourceText('createOrganisationApiError')}</span> : null}
              <button type="button" className="btn admin-btn admin-create-organisation-container__action"
                      onClick={async () => await navigate('/admin/organisations')}>{getResourceText('cancel')}</button>
              <button type="submit"
                      className="btn admin-btn admin-create-organisation-container__action">{getResourceText('createOrganisation')}</button>
            </div>
          </form>
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminCreateOrganisation;