import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import AdminPage from '../../../components/adminPage';
import { useAdminUser } from '../../../hooks/adminUser';
import PageLoading from '../../../components/pageLoading';
import ErrorHeading from '../../../components/errorHeading';
import { getResourceText } from '../../../lib/language';
import { useApi } from '../../../hooks/api';
import Icon, { GlyphNames } from '../../../components/icon';

const AdminOrganisations = () => {
  useAdminUser();
  const [organisations, setOrganisations] = useState();
  const [error, setError] = useState(false);

  const { admin: { getOrganisations } } = useApi();

  useEffect(() => {
    const getData = async () => {
      const { status, json } = await getOrganisations();

      if (status === 200) {
        setOrganisations(json);
      } else {
        setError(true);
      }
    };
    getData().catch(() => setError(true));
  }, [getOrganisations]);

  return (
    <AdminPage withBack={false}>
      <div className="admin-organisations-container">
        <div className="admin-organisations-container__main-heading-container">
          <h1 className="admin-organisations-container__main-heading">{getResourceText('organisations')}</h1>
          <Link to="/admin/organisations/create" className="btn admin-btn admin-organisations-main-heading-container__add-new" aria-label="Add new organisation"
                title="Add new organisation">
            <Icon glyphName={GlyphNames.plus}/>
          </Link>
        </div>
        {!error && !organisations ? <PageLoading/> : null}
        {error ? <ErrorHeading text="Error getting organisations"/> : null}
        {organisations && !organisations.length ? <h2 className="admin-organisations-container__sub-heading">{getResourceText('noOrganisationsFound')}</h2> : null}
        {!error && organisations ? (
          <div className="admin-organisations-list-container">
            {organisations.length ? (
              <table className="admin-organisations-table">
                <thead>
                <tr>
                  <th>{getResourceText('organisationTableHeading')}</th>
                  <th>{getResourceText('isActiveTableHeading')}</th>
                  <th>{getResourceText('actionsTableHeading')}</th>
                </tr>
                </thead>
                <tbody>
                {organisations.map(({ resourceId: organisationId, resourceName: organisationName, isActive }) =>
                  <tr key={organisationId}>
                    <td className="admin-organisations-table__center">{organisationName}</td>
                    <td className="admin-organisations-table__center">{isActive ? <Icon glyphName={GlyphNames.tick}/> : null}</td>
                    <td><Link to={`/admin/organisations/${organisationId}/edit`}>Edit</Link></td>
                  </tr>)}
                </tbody>
              </table>
            ) : null}
          </div>
        ) : null}
      </div>
    </AdminPage>
  );
};

export default AdminOrganisations;