import React from 'react';
import { Router } from '@reach/router';
import { useIFrame } from '../lib/iframe';
import Login from './login';
import Dashboard from './dashboard';
import TeacherResource from './teacher-resources/[resourceId]';
import ResourceSection from './teacher-resources/[resourceId]/sections/[sectionId]';
import ResourceWeek from './teacher-resources/[resourceId]/sections/[sectionId]/weeks/[weekId]';
import AdminDashboard from './admin';
import AdminResourcePacks from './admin/resource-packs';
import AdminUsers from './admin/users';
import AdminSubscriptions from './admin/subscriptions';
import AdminOrganisations from './admin/organisations';
import AdminResourcePack from './admin/resource-packs/[resourcePackId]/edit';
import CreateResourcePack from './admin/resource-packs/create';
import AdminCreateSection from './admin/resource-packs/[resourcePackId]/sections/create';
import AdminEditSection from './admin/resource-packs/[resourcePackId]/sections/[sectionId]/edit';
import AdminCreateWeek from './admin/resource-packs/[resourcePackId]/sections/[sectionId]/weeks/create';
import AdminEditWeek from './admin/resource-packs/[resourcePackId]/sections/[sectionId]/weeks/[weekId]/edit';
import AdminAddLesson from './admin/resource-packs/[resourcePackId]/sections/[sectionId]/weeks/[weekId]/lessons/add';
import AdminCreateLesson from './admin/resource-packs/[resourcePackId]/lessons/create';
import AdminEditLesson from './admin/resource-packs/[resourcePackId]/lessons/[lessonId]/edit';
import AdminEditLessons from './admin/resource-packs/[resourcePackId]/lessons/edit';
import AddNewUser from './admin/users/add';
import AdminCreateOrganisation from './admin/organisations/create';
import AdminEditOrganisation from './admin/organisations/[organisationId]/edit';
import AdminCreateSubscription from './admin/subscriptions/create';
import AdminCreateDownloadable from './admin/resource-packs/[resourcePackId]/downloadables/create';
import AdminAddDownloadable from './admin/resource-packs/[resourcePackId]/downloadables/add';
import AdminEditDownloadables from './admin/resource-packs/[resourcePackId]/downloadables/edit';
import AdminEditDownloadable from './admin/resource-packs/[resourcePackId]/downloadables/[downloadableId]/edit';

const Index = () => {
  useIFrame();

  return (
    <Router>
      <Login path="/login"/>
      <Dashboard path="/dashboard" default/>
      <AdminDashboard path="/admin">
        <AdminResourcePacks path="/resource-packs">
          <CreateResourcePack path="/create"/>
          <AdminResourcePack path="/:resourcePackId/edit"/>

          <AdminCreateSection path="/:resourcePackId/sections/create"/>
          <AdminEditSection path="/:resourcePackId/sections/:sectionId/edit"/>

          <AdminCreateWeek path="/:resourcePackId/sections/:sectionId/weeks/create"/>
          <AdminEditWeek path="/:resourcePackId/sections/:sectionId/weeks/:weekId/edit"/>

          <AdminAddLesson path="/:resourcePackId/sections/:sectionId/weeks/:weekId/lessons/add"/>
          <AdminCreateLesson path="/:resourcePackId/lessons/create"/>
          <AdminEditLessons path="/:resourcePackId/lessons/edit"/>
          <AdminEditLesson path="/:resourcePackId/lessons/:lessonId/edit"/>

          <AdminAddDownloadable path="/:resourcePackId/sections/:sectionId/weeks/:weekId/downloadables/add"/>
          <AdminCreateDownloadable path="/:resourcePackId/downloadables/create"/>
          <AdminEditDownloadables path="/:resourcePackId/downloadables/edit"/>
          <AdminEditDownloadable path="/:resourcePackId/downloadables/:downloadableId/edit"/>
        </AdminResourcePacks>
        <AdminUsers path="/users">
          <AddNewUser path="/add"/>
          <AddNewUser path="/:userId/edit"/>
        </AdminUsers>
        <AdminSubscriptions path="/subscriptions">
          <AdminCreateSubscription path="/create" />
        </AdminSubscriptions>
        <AdminOrganisations path="/organisations">
          <AdminCreateOrganisation path="/create" />
          <AdminEditOrganisation path="/:organisationId/edit" />
        </AdminOrganisations>
      </AdminDashboard>
      <TeacherResource path="/teacher-resources/:resourceId">
        <ResourceSection path="/sections/:sectionId">
          <ResourceWeek path="/weeks/:weekId"/>
        </ResourceSection>
      </TeacherResource>
    </Router>
  );
};

export default Index;
