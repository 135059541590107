import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import AdminPage from '../../../../../../components/adminPage';
import { getResourceText } from '../../../../../../lib/language';
import '../../../../../../sass/_admin-downloadables.scss';
import { LoadingSpinnerButton } from '../../../../../../components/loading';
import { useApi } from '../../../../../../hooks/api';
import { googleDocsFormat } from '../../../../../../lib/url';
import LabelInput from '../../../../../../components/labelInput';
import DownloadableView from '../../../../../../components/downloadableView';
import LabelSelect from '../../../../../../components/labelSelect';

// TODO edit this to be downloadable not lesson

const AdminEditDownloadable = ({ resourcePackId: resourceId, downloadableId }) => {
  const [downloadableName, setDownloadableName] = useState('');
  const [currentDownloadableName, setCurrentDownloadableName] = useState('');
  const [downloadableURL, setDownloadableURL] = useState('');
  const [resourcePack, setResourcePack] = useState();
  const [downloadableType, setDownloadableType] = useState();
  const [downloadableNameError, setDownloadableNameError] = useState(false);
  const [downloadableURLError, setDownloadableURLError] = useState(false);
  const [downloadableURLFormatError, setDownloadableURLFormatError] = useState(false);
  const [downloadableTypeError, setDownloadableTypeError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const {
    admin: { updateDownloadable: apiUpdateDownloadable, getDownloadable, getResourcePack },
  } = useApi();

  useEffect(() => {
    if (resourceId) {
      const getData = async () => {
        const [{ status, json }, { status: downloadableResponseStatus, json: downloadable }] = await Promise.all([
          getResourcePack(resourceId),
          getDownloadable({ resourceId, downloadableId }),
        ]);

        if (status === 200 && downloadableResponseStatus === 200) {
          setResourcePack(json);
          setDownloadableName(downloadable.resourceName);
          setCurrentDownloadableName(downloadable.resourceName);
          setDownloadableURL(downloadable.downloadableURL);
          setDownloadableType(downloadable.downloadableType);
        } else {
          setError(true);
        }
      };

      getData().catch(() => setError(true));
    }
  }, [resourceId]);

  const validate = () => {
    if (!downloadableName) {
      setDownloadableNameError(true);
    } else {
      setDownloadableNameError(false);
    }

    if (!downloadableURL) {
      setDownloadableURLError(true);
    } else {
      setDownloadableURLError(false);
    }

    if (downloadableURL && !googleDocsFormat.exec(downloadableURL)) {
      setDownloadableURLFormatError(true);
    } else {
      setDownloadableURLFormatError(false);
    }

    if (!downloadableType) {
      setDownloadableTypeError(true);
    } else {
      setDownloadableTypeError(false);
    }
  };

  const isValid = () => {
    if (!!downloadableName?.trim() && !!downloadableURL.trim() && !!downloadableType) {
      return !(downloadableURL && !googleDocsFormat.exec(downloadableURL));
    }
    return false;
  };

  const updateDownloadable = async (event) => {
    event.preventDefault();
    setApiError(false);
    validate();

    if (isValid()) {
      setLoading(true);
      const { status } = await apiUpdateDownloadable({
        resourceId,
        downloadableId,
        downloadableName,
        downloadableURL,
        downloadableType,
      });

      if (status === 200) {
        await navigate(`/admin/resource-packs/${resourceId}/downloadables/edit`);
      } else {
        setApiError(true);
      }

      setLoading(false);
    }
    return false;
  };
  return (
    <AdminPage withBack>
      <h2 className="admin-edit-downloadable-heading">{resourcePack?.resourceName}</h2>
      <h3 className="admin-edit-downloadable-heading-smaller">{currentDownloadableName}</h3>
      <div className="admin-edit-downloadable-container">
        <h2 className="admin-edit-downloadable-container__heading">
          <span className="admin-edit-downloadable-container__heading-text">
            {getResourceText('updateDownloadable')}
          </span>
          {loading ? <LoadingSpinnerButton /> : null}
        </h2>
        <div className="admin-edit-downloadable-container__body-container">
          <form onSubmit={(event) => updateDownloadable(event)}>
            <LabelInput
              value={downloadableName}
              labelKey="name"
              error={downloadableNameError}
              onChange={setDownloadableName}
              classNamePrefix="admin-edit-downloadable-container"
            />
            <LabelInput
              value={downloadableURL}
              onChange={setDownloadableURL}
              error={downloadableURLError || downloadableURLFormatError}
              errorCode={downloadableURLFormatError ? 'downloadableURLFormatError' : 'downloadableURLError'}
              labelKey="downloadableURL"
              classNamePrefix="admin-edit-downloadable-container"
            />
            <LabelSelect
              labelKey="resourceHTMLType"
              error={downloadableTypeError}
              options={[
                { label: 'Please select', value: '' },
                { label: 'PDF', value: 'pdf' },
                { label: 'Image', value: 'image' },
              ]}
              value={downloadableType}
              onChange={setDownloadableType}
              classNamePrefix="admin-create-downloadable-container"
            />
            {downloadableURL ? (
              <div className="admin-edit-downloadable-container__label-inputs-container">
                <label className="admin-edit-downloadable-container__label-input-container">
                  <span className="admin-edit-downloadable-container__label">
                    {getResourceText('downloadablePreview')}
                  </span>
                  <div className="admin-edit-downloadable-container__preview-container">
                    <div
                      className={`admin-edit-downloadable-container__resource-preview${
                        downloadableType === 'image' ? '-img' : ''
                      }`}
                    >
                      <DownloadableView
                        googleDocsURL={downloadableURL}
                        type={downloadableType}
                        interactive={downloadableType !== 'image'}
                      />
                    </div>
                  </div>
                </label>
              </div>
            ) : null}
            <div className="admin-edit-downloadable-container__actions">
              {apiError ? (
                <span className="admin-edit-downloadable-container__action-error">
                  {getResourceText('updateDownloadablesApiError')}
                </span>
              ) : null}
              <button
                type="button"
                className="btn admin-btn admin-edit-downloadable-container__action"
                onClick={async () => await navigate(`/admin/resource-packs/${resourceId}/downloadables/edit`)}
              >
                {getResourceText('cancel')}
              </button>
              <button type="submit" className="btn admin-btn admin-edit-downloadable-container__action">
                {getResourceText('updateDownloadable')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminEditDownloadable;
