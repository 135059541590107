import React from 'react';
import AdminPage from '../../components/adminPage';
import { getResourceText } from '../../lib/language';

const AdminSubscriptions = () => {
  return (
    <AdminPage>
      <div className="admin-dashboard-container">
        <h1 className="admin-dashboard-container__main-heading">{getResourceText('subscriptions')}</h1>
      </div>
    </AdminPage>
  );

};

export default AdminSubscriptions;