import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import AdminPage from '../../../../../../../components/adminPage';
import { getResourceText } from '../../../../../../../lib/language';
import { LoadingSpinnerButton } from '../../../../../../../components/loading';
import { useApi } from '../../../../../../../hooks/api';

const AdminCreateWeek = ({ resourcePackId: resourceId, sectionId }) => {
  const [weekName, setWeekName] = useState('');
  const [resourcePack, setResourcePack] = useState();
  const [weekNameError, setWeekNameError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);

  const { admin: { createWeek: apiCreateWeek, getResourcePack } } = useApi();

  useEffect(() => {
    if (resourceId) {
      const getData = async () => {
        const { status, json } = await getResourcePack(resourceId);

        if (status === 200) {
          setResourcePack(json);
        } else {
          setError(true);
        }
      };

      getData().catch(() => setError(true));
    }
  }, [getResourcePack, resourceId]);

  const validate = () => {
    if (!weekName) {
      setWeekNameError(true);
    } else {
      setWeekNameError(false);
    }
  };

  const isValid = () => !!weekName?.trim();

  const createWeek = async (event) => {
    event.preventDefault();
    setApiError(false);
    validate();

    if (isValid()) {
      setLoading(true);

      const { status } = await apiCreateWeek({ resourceId, sectionId, weekName });

      if (status === 201) {
        await navigate(`/admin/resource-packs/${resourceId}/edit`);
      } else {
        setApiError(true);
      }

      setLoading(false);
    }
    return false;
  };
  return (
    <AdminPage withBack>
      <h2 className="admin-create-week-heading">{resourcePack?.resourceName}</h2>
      <div className="admin-create-week-container">
        <h2 className="admin-create-week-container__heading">
          <span className="admin-create-week-container__heading-text">
            {getResourceText('createWeek')}
          </span>
          {loading ? <LoadingSpinnerButton/> : null}
        </h2>
        <div className="admin-create-week-container__body-container">
          <form onSubmit={(event) => createWeek(event)}>
            <div className="admin-create-week-container__label-inputs-container">
              <label className="admin-create-week-container__label-input-container">
                <span className="admin-create-week-container__label">{getResourceText('name')}</span>
                <div className="admin-create-week-container__input-container">
                  <input type="text" className="admin-create-week-container__input" value={weekName}
                         onChange={({ target: { value } }) => setWeekName(value)}/>
                </div>
              </label>
              {weekNameError ? <span
                className="admin-create-week-container__error">{getResourceText('weekNameError')}</span> : null}
            </div>
            <div className="admin-create-week-container__actions">
              {apiError ? <span
                className="admin-create-week-container__action-error">{getResourceText('createWeekApiError')}</span> : null}
              <button type="button" className="btn admin-btn admin-create-week-container__action"
                      onClick={async () => await navigate(`/admin/resource-packs/${resourceId}/edit`)}>{getResourceText('cancel')}</button>
              <button type="submit"
                      className="btn admin-btn admin-create-week-container__action">{getResourceText('createWeek')}</button>
            </div>
          </form>
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminCreateWeek;