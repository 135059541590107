import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { getResourceText } from '../../../../../../lib/language';
import Loading, { LoadingSpinner, LoadingText } from '../../../../../../components/loading';
import Icon, { GlyphNames } from '../../../../../../components/icon';
import UserPage from '../../../../../../components/userPage';
import LessonView, { formatGoogleSlidesURL, formatYoutubeURL } from '../../../../../../components/lessonView';
import { getData } from '../../../../../../lib/store';
import { useIFrame } from '../../../../../../lib/iframe';
import { useApi } from '../../../../../../hooks/api';
import { formatForDownloadGoogleDriveURL } from '../../../../../../lib/url';
import { sortResourcesByName } from '../../../../../../lib/sort';
import DownloadableView from '../../../../../../components/downloadableView';
import TeacherNotes from '../../../../../../components/teacherNotes';

const SlideModal = ({ type, url, closeModal }) =>
  type === 'google' ? (
    <GoogleSlideModal slideURL={url} closeModal={closeModal} />
  ) : (
    <YoutubeModal url={url} closeModal={closeModal} />
  );

const YoutubeModal = ({ url, closeModal }) => {
  const height = getData('parentHeight');
  const heightPx = height ? `${height}px` : undefined;

  const escapeListener = useCallback(
    ({ key } = {}) => {
      if (key === 'Escape') {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    window.addEventListener('keyup', escapeListener);
    return () => {
      window.removeEventListener('keyup', escapeListener);
    };
  }, [escapeListener]);

  return (
    <div className="slide-modal-container">
      <div className="slide-modal" style={{ height: heightPx }}>
        <button className="slide-modal__close" aria-label="Close" onClick={() => closeModal()}>
          <Icon glyphName={GlyphNames.close} css="slide-modal__close-icon" />
        </button>
        <iframe title={url} className="slide-modal__iframe" src={formatYoutubeURL(url)} />
        <Loading css="slide-modal__loading">
          <LoadingSpinner />
        </Loading>
      </div>
    </div>
  );
};

const GoogleSlideModal = memo(({ slideURL, closeModal }) => {
  const height = getData('parentHeight');
  const heightPx = height ? `${height}px` : undefined;

  const escapeListener = useCallback(
    ({ key } = {}) => {
      if (key === 'Escape') {
        closeModal();
      }
    },
    [closeModal]
  );

  useEffect(() => {
    window.addEventListener('keyup', escapeListener);
    return () => {
      window.removeEventListener('keyup', escapeListener);
    };
  }, [escapeListener]);

  return (
    <div className="slide-modal-container">
      <div className="slide-modal" style={{ height: heightPx }}>
        <button className="slide-modal__close" aria-label="Close" onClick={() => closeModal()}>
          <Icon glyphName={GlyphNames.close} css="slide-modal__close-icon" />
        </button>
        <iframe title={slideURL} className="slide-modal__iframe" src={formatGoogleSlidesURL(slideURL)} />
        <Loading css="slide-modal__loading">
          <LoadingSpinner />
        </Loading>
      </div>
    </div>
  );
});

const LessonLinks = memo(({ lessons, setSlide }) => (
  <div className="lesson-links-container">
    {lessons.filter(Boolean).map(({ resourceName, resourceId: lessonId, googleSlidesURL, youtubeURL }) => (
      <div className="lesson-container" key={lessonId}>
        <h3 className="lesson-container__lesson-name">{resourceName}</h3>
        <div key={lessonId} className="lesson-container__lesson-resource">
          <LessonView googleSlidesURL={googleSlidesURL} youtubeURL={youtubeURL} />
          <button
            className="lesson-container__launch-slide"
            aria-label={`Launch resource, ${googleSlidesURL ?? youtubeURL}`}
            onClick={() =>
              setSlide({
                type: googleSlidesURL ? 'google' : 'youtube',
                url: googleSlidesURL ? googleSlidesURL : youtubeURL,
              })
            }
          ></button>
        </div>
      </div>
    ))}
  </div>
));

const DownloadLinks = memo(({ downloadables }) => {
  const filteredDownloadables = downloadables.filter(Boolean);
  return (
    <div className="downloadable-links-container">
      <h2 className="downloadable-links-container__title">Downloadable Resources</h2>
      {!filteredDownloadables.length ? (
        <p className="downloadable-links-container__text">No downloadable resources for this week</p>
      ) : null}
      {filteredDownloadables.map(({ resourceName, resourceId: downloadableId, downloadableURL, downloadableType }) => (
        <div className="downloadable-container" key={downloadableId}>
          <h3 className="downloadable-container__lesson-name">{resourceName}</h3>
          <div key={downloadableId} className="downloadable-container__downloadable-resource">
            <a
              className="downloadable-container__open-resource"
              aria-label={`Open document, ${formatForDownloadGoogleDriveURL(downloadableURL)}`}
              href={formatForDownloadGoogleDriveURL(downloadableURL)}
              target="_blank"
              rel="noreferrer"
            >
              <DownloadableView googleDocsURL={downloadableURL} type={downloadableType} />
            </a>
          </div>
        </div>
      ))}
    </div>
  );
});

const getAllLessonResources = async ({ fetchFunction, resourceId, sectionId, weekId, ids }) => {
  return await Promise.all(
    ids.map(async (id) => {
      const { status, json } = await fetchFunction({ resourceId, sectionId, weekId, id });

      if (status === 200) {
        return json;
      }
    })
  );
};

const ResourceError = memo(() => (
  <div className="lessons-container__no-resources">
    <h2 className="lessons-container__sub-heading">{getResourceText('sectionError')}</h2>
    <p className="lessons-container__text">{getResourceText('sectionErrorText')}</p>
  </div>
));

const ResourceLoading = memo(() => (
  <div className="lessons-container__loading">
    <Loading>
      <LoadingSpinner />
      <LoadingText>{getResourceText('lessonsLoading')}</LoadingText>
    </Loading>
  </div>
));

const ResourceWeek = ({ resourceId, sectionId, weekId }) => {
  const [lessons, setLessons] = useState();
  const [downloadables, setDownloadables] = useState();
  const [week, setWeek] = useState();
  const [section, setSection] = useState();
  const [resource, setResource] = useState();
  const [error, setError] = useState(false);
  const [slide, setSlide] = useState();
  const { getResourcePack, getSection, getWeek, getLesson, getDownloadable } = useApi();
  const { getParentHeight } = useIFrame();

  useEffect(() => {
    if (resourceId && sectionId && weekId) {
      const getData = async () => {
        const [
          { status: resourcePackStatus, json: resourcePackData },
          { status: sectionStatus, json: sectionData },
          { status: weekStatus, json: weekData },
        ] = await Promise.all([
          getResourcePack(resourceId),
          getSection({ resourceId, sectionId }),
          getWeek({ resourceId, sectionId, weekId }),
        ]);

        if (resourcePackStatus === 200 && sectionStatus === 200 && weekStatus === 200) {
          setResource(resourcePackData);
          setSection(sectionData);

          const { lessons: lessonIds = [], downloadables: downloadableIds = [], ...rest } = weekData;
          setWeek({ ...rest, lessons: lessonIds, downloadables: downloadableIds });
          const [allLessons, allDownloadables] = await Promise.all([
            getAllLessonResources({
              fetchFunction: ({ id, ...params }) => getLesson({ lessonId: id, ...params }),
              resourceId,
              sectionId,
              weekId,
              ids: lessonIds,
            }),
            getAllLessonResources({
              fetchFunction: ({ id, ...params }) => getDownloadable({ downloadableId: id, ...params }),
              resourceId,
              sectionId,
              weekId,
              ids: downloadableIds,
            }),
          ]);
          setLessons(sortResourcesByName(allLessons));
          setDownloadables(sortResourcesByName(allDownloadables));
        } else {
          setError(true);
          // need to handle this below
        }
      };

      getData().catch(() => setError(true));
    }
  }, [resourceId, sectionId, weekId, getDownloadable, getLesson, getResourcePack, getSection, getWeek]);

  useEffect(() => {
    getParentHeight();
  }, [getParentHeight]);

  return (
    <UserPage
      title={
        resource && section && week
          ? `${resource?.resourceName} / ${section?.resourceName} / ${week?.resourceName}`
          : null
      }
    >
      <div className="lessons-container">
        {resource && section && week ? (
          <Fragment>
            <div className="lesson-container__heading-links">
              <Link to="/dashboard" className="lessons-container__heading-link">
                {getResourceText('backToDashboardLinkText')}
              </Link>{' '}
              /
              <Link to={`/teacher-resources/${resourceId}`} className="lessons-container__heading-link">
                {resource?.resourceName}
              </Link>{' '}
              /
              <Link
                to={`/teacher-resources/${resourceId}/sections/${sectionId}`}
                className="lessons-container__heading-link"
              >
                {section?.resourceName}
              </Link>
            </div>
            <TeacherNotes
              teacherNotesURL={resource?.teacherNotesURL}
              sectionNotesURL={section?.teacherNotesURL}
              resourcePackName={resource?.resourceName}
              sectionName={section?.resourceName}
            />
            {lessons ? <LessonLinks lessons={lessons} setSlide={(slide) => setSlide(slide)} /> : null}
            {downloadables ? <DownloadLinks downloadables={downloadables} /> : null}
            {slide ? <SlideModal type={slide.type} url={slide.url} closeModal={() => setSlide(null)} /> : null}
          </Fragment>
        ) : null}
        {!lessons && !error ? <ResourceLoading /> : null}
        {error ? <ResourceError /> : null}
      </div>
    </UserPage>
  );
};

export default ResourceWeek;
