import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import AdminPage from '../../../../../../components/adminPage';
import { getResourceText } from '../../../../../../lib/language';
import { LoadingSpinnerButton } from '../../../../../../components/loading';
import { useApi } from '../../../../../../hooks/api';
import LessonView from '../../../../../../components/lessonView';
import { googleSlidesFormat } from '../../../../../../lib/url';
import LabelInput from '../../../../../../components/labelInput';

const AdminEditLesson = ({ resourcePackId: resourceId, lessonId }) => {
  const [lessonName, setLessonName] = useState('');
  const [currentLessonName, setcurrentLessonName] = useState('');
  const [googleSlidesURL, setGoogleSlidesURL] = useState('');
  const [youtubeURL, setYoutubeURL] = useState('');

  const [resourcePack, setResourcePack] = useState();
  const [lessonNameError, setLessonNameError] = useState(false);
  const [googleSlidesOrYoutubeError, setGoogleSlidesOrYoutubeError] = useState(false);
  const [googleSlidesFormatError, setGoogleSlidesFormatError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);

  const { admin: { updateLesson: apiUpdateLesson, getLesson, getResourcePack } } = useApi();

  useEffect(() => {
    if (resourceId) {
      const getData = async () => {
        const [
          { status, json },
          { status: lessonResponseStatus, json: lesson }] =
          await Promise.all([getResourcePack(resourceId), getLesson({ resourceId, lessonId })]);

        if (status === 200 && lessonResponseStatus === 200) {
          setResourcePack(json);
          setLessonName(lesson.resourceName);
          setcurrentLessonName(lesson.resourceName);
          setGoogleSlidesURL(lesson.googleSlidesURL);
          setYoutubeURL(lesson.youtubeURL);
        } else {
          setError(true);
        }
      };

      getData().catch(() => setError(true));
    }
  }, [getLesson, getResourcePack, lessonId, resourceId]);

  const validate = () => {
    if (!lessonName) {
      setLessonNameError(true);
    } else {
      setLessonNameError(false);
    }

    if ((!googleSlidesURL && !youtubeURL) || (googleSlidesURL && youtubeURL)) {
      setGoogleSlidesOrYoutubeError(true);
    } else {
      setGoogleSlidesOrYoutubeError(false);
    }

    if (googleSlidesURL && !googleSlidesFormat.exec(googleSlidesURL)) {
      setGoogleSlidesFormatError(true);
    } else {
      setGoogleSlidesFormatError(false);
    }
  };

  const isValid = () => {
    if (!!lessonName?.trim() && ((googleSlidesURL || youtubeURL) && !(googleSlidesURL && youtubeURL))) {
      return !(googleSlidesURL && !googleSlidesFormat.exec(googleSlidesURL));
    }
    return false;
  };

  const updateLesson = async (event) => {
    event.preventDefault();
    setApiError(false);
    validate();

    if (isValid()) {
      setLoading(true);
      // update sectionId, weekId with lessonId
      const { status } = await apiUpdateLesson({ resourceId, lessonId, lessonName, googleSlidesURL, youtubeURL });

      if (status === 200) {
        await navigate(`/admin/resource-packs/${resourceId}/lessons/edit`);
      } else {
        setApiError(true);
      }

      setLoading(false);
    }
    return false;
  };
  return (
    <AdminPage withBack>
      <h2 className="admin-create-lesson-heading">{resourcePack?.resourceName}</h2>
      <h3 className="admin-create-lesson-heading-smaller">{currentLessonName}</h3>
      <div className="admin-create-lesson-container">
        <h2 className="admin-create-lesson-container__heading">
          <span className="admin-create-lesson-container__heading-text">
            {getResourceText('updateLesson')}
          </span>
          {loading ? <LoadingSpinnerButton/> : null}
        </h2>
        <div className="admin-create-lesson-container__body-container">
          <form onSubmit={(event) => updateLesson(event)}>
            <LabelInput value={lessonName} labelKey="name" error={lessonNameError} onChange={setLessonName} classNamePrefix="admin-create-lesson-container" />
            <LabelInput value={googleSlidesURL} labelKey="googleSlidesURL" error={googleSlidesOrYoutubeError || googleSlidesFormatError} errorCode={googleSlidesFormatError ? 'googleSlidesFormatError' : 'googleSlidesOrYouTubeError'} onChange={setGoogleSlidesURL} classNamePrefix="admin-create-lesson-container" />
            <LabelInput value={youtubeURL} labelKey="youtubeURL" error={googleSlidesOrYoutubeError} onChange={setYoutubeURL} classNamePrefix="admin-create-lesson-container" />
            {googleSlidesURL || youtubeURL ? (
              <div className="admin-create-lesson-container__label-inputs-container">
                <label className="admin-create-lesson-container__label-input-container">
                  <span className="admin-create-lesson-container__label">{getResourceText('lessonPreview')}</span>
                  <div className="admin-create-lesson-container__preview-container">
                    <div className="admin-create-lesson-container__resource-preview">
                    <LessonView youtubeURL={youtubeURL} googleSlidesURL={googleSlidesURL}/>
                    </div>
                  </div>
                </label>
              </div>
            ) : null}
            <div className="admin-create-lesson-container__actions">
              {apiError ? <span
                className="admin-create-lesson-container__action-error">{getResourceText('updateLessonApiError')}</span> : null}
              <button type="button" className="btn admin-btn admin-create-lesson-container__action"
                      onClick={async () => await navigate(`/admin/resource-packs/${resourceId}/lessons/edit`)}>{getResourceText('cancel')}</button>
              <button type="submit"
                      className="btn admin-btn admin-create-lesson-container__action">{getResourceText('updateLesson')}</button>
            </div>
          </form>
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminEditLesson;