import React, { useEffect, useMemo, useState } from 'react';
import { Link, navigate } from 'gatsby';
import classNames from 'classnames';
import AdminPage from './adminPage';
import { getResourceText } from '../lib/language';
import { LoadingSpinner, LoadingSpinnerButton } from './loading';
import { useApi } from '../hooks/api';
import DownloadableView from './downloadableView';

const Downloadable = ({
  resourceId,
  sectionId,
  weekId,
  downloadable,
  selectedDownloadableId,
  setSelectedDownloadableId,
  withAdd,
}) => {
  const [selected, setSelected] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [error, setError] = useState(false);
  const {
    admin: { updateSection, updateWeek },
  } = useApi();

  useEffect(() => {
    if (selectedDownloadableId && selectedDownloadableId !== downloadable.resourceId) {
      setSelected(false);
    }
  }, [selectedDownloadableId, downloadable.resourceId]);

  const toggleSelected = () => {
    setSelected(!selected);
    setSelectedDownloadableId(downloadable.resourceId);
  };

  const addDownloadable = async () => {
    setAddLoading(true);

    const apiUpdate = async () => {
      if (sectionId && weekId) {
        return await updateWeek({ resourceId, sectionId, weekId, downloadablesToAdd: [downloadable.resourceId] });
      } else if (sectionId && !weekId) {
        return await updateSection({ resourceId, sectionId, downloadablesToAdd: [downloadable.resourceId] });
      }
    };

    const { status } = await apiUpdate();

    if (status === 200) {
      let hash;

      if (weekId) {
        hash = `#week-${weekId}`;
      } else if (sectionId) {
        hash = `#section-${sectionId}`;
      }

      await navigate(`/admin/resource-packs/${resourceId}/edit${hash}`);
    } else {
      // deal with this below, lesson has not been added correctly
      setAddLoading(false);
      setError(true);
    }
  };

  // might be better as a button
  return (
    <div
      className={classNames(
        'admin-add-downloadable-container__downloadable admin-add-downloadable-container__downloadable--selectable',
        {
          'admin-add-downloadable-container__downloadable--selected': selected,
        }
      )}
      onClick={() => (downloadable ? toggleSelected() : null)}
      role="button"
    >
      {!downloadable && !error ? <LoadingSpinner /> : null}
      {downloadable ? (
        <>
          <h3 className="admin-add-downloadable-container__downloadable-heading">{downloadable.resourceName}</h3>
          <div className="admin-add-downloadable-container__downloadable-resource-container">
            <DownloadableView googleDocsURL={downloadable.downloadableURL} type={downloadable.downloadableType} />
          </div>
          {selected ? (
            <div className="admin-add-downloadable-container__downloadable-actions-container">
              <Link
                to={`/admin/resource-packs/${resourceId}/downloadables/${downloadable.resourceId}/edit`} // add query string to this bit
                className="btn admin-btn admin-add-downloadable-container__downloadable-actions-container__action"
              >
                {getResourceText('edit')}
              </Link>
              {withAdd ? (
                <button
                  onClick={() => addDownloadable()}
                  className="btn admin-btn admin-add-downloadable-container__downloadable-actions-container__action"
                  disabled={addLoading}
                >
                  {addLoading ? <LoadingSpinnerButton /> : getResourceText('add')}
                </button>
              ) : null}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

const Downloadables = ({ resourceId, sectionId, weekId, withAdd }) => {
  const [selectedDownloadableId, setSelectedDownloadableId] = useState();
  const [downloadables, setDownloadables] = useState();
  const [, setError] = useState(false);
  const {
    admin: { getDownloadables },
  } = useApi();

  useEffect(() => {
    getDownloadables({ resourceId })
      .then(({ status, json }) => {
        if (status === 200) {
          setDownloadables(json);
        } else {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [getDownloadables, resourceId]);

  return (
    <div className="admin-add-downloadable-container__downloadables">
      {downloadables ? downloadables.map((downloadable) => (
        <Downloadable
          key={downloadable.resourceId}
          resourceId={resourceId}
          sectionId={sectionId}
          weekId={weekId}
          downloadable={downloadable}
          selectedDownloadableId={selectedDownloadableId}
          setSelectedDownloadableId={setSelectedDownloadableId}
          withAdd={withAdd}
        />
      )) :
        <LoadingSpinner />}
    </div>
  );
};

const AdminDownloadablePage = ({ resourceId, sectionId, weekId, withAdd }) => {
  const [resourcePack, setResourcePack] = useState();
  const [, setError] = useState(false);
  const {
    admin: { getResourcePack },
  } = useApi();

  const queryString = useMemo(() => {
    if (!sectionId && !weekId) return '';

    let query = '?';

    if (sectionId) {
      query = `${query}sectionId=${sectionId}`;
    }

    if (weekId) {
      query = `${query}&weekId=${weekId}`;
    }

    return query;
  }, [sectionId, weekId]);

  useEffect(() => {
    if (resourceId) {
      const getData = async () => {
        const { status, json } = await getResourcePack(resourceId);

        if (status === 200) {
          setResourcePack(json);
        } else {
          setError(true);
        }
      };

      getData().catch(() => setError(true));
    }
  }, [getResourcePack, resourceId]);

  return (
    <AdminPage withBack={true}>
      <div className="admin-add-downloadable-container">
        <h2 className="admin-add-downloadable-container__main-heading">{resourcePack?.resourceName}</h2>
        <h3 className="admin-add-downloadable-container__sub-heading">{getResourceText('downloadables')}</h3>
        <div className="admin-add-downloadable-container__body-container">
          {resourcePack && !resourcePack?.downloadables?.length ? (
            <p>{getResourceText('noDownloadablesInResourcePack')}</p>
          ) : null}
          {resourcePack && resourcePack?.downloadables?.length ? (
            <Downloadables
              resourceId={resourceId}
              sectionId={sectionId}
              weekId={weekId}
              downloadableIds={resourcePack.downloadables}
              withAdd={withAdd}
            />
          ) : null}
          <div className="admin-add-downloadable-container__actions-container">
            <Link
              to={`/admin/resource-packs/${resourceId}/edit`}
              className="btn admin-btn admin-add-lesson-container__actions-container-action"
            >
              {getResourceText('backToResourcePack')}
            </Link>
            <Link
              to={`/admin/resource-packs/${resourceId}/downloadables/create${queryString}`}
              className="btn admin-btn"
            >
              {getResourceText('createDownloadable')}
            </Link>
          </div>
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminDownloadablePage;
