import React, { FC } from 'react';
import { formatForDownloadGoogleDriveURL } from '../lib/url';
import { getResourceText } from '../lib/language';

const TeacherNotes: FC<Props> = ({ sectionName, resourcePackName, teacherNotesURL, sectionNotesURL }) => {
  if (!teacherNotesURL && !sectionNotesURL) {
    return null;
  }
  const url = formatForDownloadGoogleDriveURL(sectionNotesURL ?? teacherNotesURL );
  const downloadName = sectionNotesURL ? `${resourcePackName}-${sectionName}` : resourcePackName;
  const linkText = sectionNotesURL ? `${resourcePackName} / ${sectionName}` : resourcePackName;

  return (
    <a
      href={url}
      className="sections-container_teacher-notes"
      rel="noreferrer noopener"
      target="_blank"
      download={`${downloadName}-teacher-notes`}
    >
      {getResourceText('downloadTeacherNotesFor')} {linkText}
    </a>
  );
};

type Props = {
  sectionName?: string;
  resourcePackName?: string;
  teacherNotesURL?: string;
  sectionNotesURL?: string;
};

export default TeacherNotes;
