import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import AdminPage from '../../../../components/adminPage';
import { getResourceText } from '../../../../lib/language';
import { LoadingSpinnerButton } from '../../../../components/loading';
import { useApi } from '../../../../hooks/api';
import LabelCheckboxInput from '../../../../components/labelCheckboxInput';
import LabelInput from '../../../../components/labelInput';

const AdminEditOrganisation = ({ organisationId }) => {
  const [organisationName, setOrganisationName] = useState('');
  const [currentOrganisationName, setCurrentOrganisationName] = useState('');
  const [organisationNameError, setOrganisationNameError] = useState(false);
  const [isActive, setIsActive] = useState();
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);

  const { admin: { updateOrganisation: apiUpdateOrganisation, getOrganisation } } = useApi();

  useEffect(() => {
    if (organisationId) {
      const getData = async () => {
        const { status, json: { resourceName, isActive: isActiveFromApi } } = await getOrganisation(organisationId);

        if (status === 200) {
          setOrganisationName(resourceName);
          setCurrentOrganisationName(resourceName);
          setIsActive(isActiveFromApi);
        } else {
          setError(true);
        }
      };

      getData().catch(() => setError(true));
    }
  }, [organisationId, getOrganisation]);

  const validate = () => {
    if (!organisationName) {
      setOrganisationNameError(true);
    } else {
      setOrganisationNameError(false);
    }
  };

  const isValid = () => !!organisationName?.trim();

  const updateOrganisation = async (event) => {
    event.preventDefault();
    setApiError(false);
    validate();

    if (isValid()) {
      setLoading(true);

      const { status } = await apiUpdateOrganisation({ organisationId, organisationName, isActive });

      if (status === 200) {
        await navigate('/admin/organisations');
      } else {
        setApiError(true);
      }

      setLoading(false);
    }
    return false;
  };
  return (
    <AdminPage withBack>
      <h2 className="admin-edit-organisation-heading">{currentOrganisationName}</h2>
      <div className="admin-edit-organisation-container">
        <h2 className="admin-edit-organisation-container__heading">
          <span className="admin-edit-organisation-container__heading-text">
            {getResourceText('updateOrganisation')}
          </span>
          {loading ? <LoadingSpinnerButton/> : null}
        </h2>
        <div className="admin-edit-organisation-container__body-container">
          <form onSubmit={(event) => updateOrganisation(event)}>
            <LabelInput value={organisationName} onChange={(updated) => setOrganisationName(updated)} error={organisationNameError} labelKey="name" classNamePrefix="admin-edit-organisation-container" />
            <LabelCheckboxInput labelKey="isActive" checked={isActive} onChange={(val) => setIsActive(val)} classNamePrefix="admin-edit-organisation-container"/>
            <div className="admin-edit-organisation-container__actions">
              {apiError ? <span
                className="admin-edit-organisation-container__action-error">{getResourceText('editOrganisationApiError')}</span> : null}
              <button type="button" className="btn admin-btn admin-edit-organisation-container__action"
                      onClick={async () => await navigate('/admin/organisations')}>{getResourceText('cancel')}</button>
              <button type="submit"
                      className="btn admin-btn admin-edit-organisation-container__action">{getResourceText('updateOrganisation')}</button>
            </div>
          </form>
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminEditOrganisation;