import React from 'react';
import AdminPage from '../../components/adminPage';

const AdminDashboard = () => {
  return (
    <AdminPage withBack={false}>
      <div className="admin-dashboard-container">
        <p className="admin-dashboard-container__welcome">Welcome to ABC of Opera Teacher Resource admin system</p>
        <p className="admin-dashboard-container__welcome">Use the links above to navigate around.</p>
      </div>
    </AdminPage>

  );

};

export default AdminDashboard;