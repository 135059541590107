import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import AdminPage from '../../../../../components/adminPage';
import { getResourceText } from '../../../../../lib/language';
import { LoadingSpinnerButton } from '../../../../../components/loading';
import { useApi } from '../../../../../hooks/api';

const AdminCreateSection = ({ resourcePackId }) => {
  const [sectionName, setSectionName] = useState('');
  const [resourcePack, setResourcePack] = useState();
  const [sectionNameError, setSectionNameError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);

  const { admin: { createSection: apiCreateSection, getResourcePack } } = useApi();

  useEffect(() => {
    const getData = async () => {
      const { status, json } = await getResourcePack(resourcePackId);

      if (status === 200) {
        setResourcePack(json);
      } else {
        setError(true);
      }
    };

    getData().catch(() => setError(true));
  }, [getResourcePack, resourcePackId]);

  const validate = () => {
    if (!sectionName) {
      setSectionNameError(true);
    } else {
      setSectionNameError(false);
    }
  };

  const isValid = () => !!sectionName?.trim();

  const createSection = async (event) => {
    event.preventDefault();
    setApiError(false);
    validate();

    if (isValid()) {
      setLoading(true);

      const { status } = await apiCreateSection({ resourcePackId, sectionName });

      if (status === 201) {
        await navigate(`/admin/resource-packs/${resourcePackId}/edit`);
      } else {
        setApiError(true);
      }

      setLoading(false);
    }
    return false;
  };
  return (
    <AdminPage withBack>
      <h2 className="admin-create-section-heading">{resourcePack?.resourceName}</h2>
      <div className="admin-create-section-container">
        <h2 className="admin-create-section-container__heading">
          <span className="admin-create-section-container__heading-text">
            {getResourceText('createLessonGroup')}
          </span>
          {loading ? <LoadingSpinnerButton/> : null}
        </h2>
        <div className="admin-create-section-container__body-container">
          <form onSubmit={(event) => createSection(event)}>
            <div className="admin-create-section-container__label-inputs-container">
              <label className="admin-create-section-container__label-input-container">
                <span className="admin-create-section-container__label">{getResourceText('name')}</span>
                <div className="admin-create-section-container__input-container">
                  <input type="text" className="admin-create-section-container__input" value={sectionName}
                         onChange={({ target: { value } }) => setSectionName(value)}/>
                </div>
              </label>
              {sectionNameError ? <span
                className="admin-create-section-container__error">{getResourceText('sectionNameError')}</span> : null}
            </div>
            <div className="admin-create-section-container__actions">
              {apiError ? <span
                className="admin-create-section-container__action-error">{getResourceText('createSectionApiError')}</span> : null}
              <button type="button" className="btn admin-btn admin-create-section-container__action"
                      onClick={async () => await navigate(`/admin/resource-packs/${resourcePackId}/edit`)}>{getResourceText('cancel')}</button>
              <button type="submit"
                      className="btn admin-btn admin-create-section-container__action">{getResourceText('createLessonGroup')}</button>
            </div>
          </form>
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminCreateSection;