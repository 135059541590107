import React, { memo, useEffect, useState } from 'react';
import { graphql, Link, navigate, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getResourceText } from '../lib/language';
import Loading, { LoadingSpinner, LoadingText } from '../components/loading';
import { getData, USER_KEY } from '../lib/store';
import UserPage from '../components/userPage';
import { useApi } from '../hooks/api';

const dashboardResourcesImageAltText = ['Wagner', 'Professor Peri', 'Handel', 'Francesca', 'Mozart', 'Beethoven'];

const DashboardError = memo(() => (
  <div className="dashboard-container__no-resources">
    <h2 className="dashboard-container__sub-heading">{getResourceText('dashboardError')}</h2>
    <p>{getResourceText('dashboardErrorText')}</p>
  </div>
));

const DashboardLoading = memo(() => (
  <div className="dashboard-container__loading">
    <Loading>
      <LoadingSpinner />
      <LoadingText>{getResourceText('dashboardResourcesLoading')}</LoadingText>
    </Loading>
  </div>
));

const DashboardNoResourcesFound = () => (
  <div className="dashboard-container__no-resources">
    <h2 className="dashboard-container__sub-heading">{getResourceText('dashboardNoResourcesSubHeading')}</h2>
    <p>{getResourceText('dashboardNoResourcesReasonText')}</p>
    <ul className="dashboard-container__list">
      <li>{getResourceText('dashboardNoResourcesReasonOne')}</li>
      <li>{getResourceText('dashboardNoResourcesReasonTwo')}</li>
    </ul>
  </div>
);

const DashboardResourcesContainer = memo(({ resources = [], lastResourceAccessed }) => (
  <div className="dashboard-container__resources-container">
    {!resources.length ? (
      <DashboardNoResourcesFound />
    ) : (
      <DashboardResources resources={resources} lastResourceAccessed={lastResourceAccessed} />
    )}
  </div>
));

const DashboardResources = ({ resources = [], lastResourceAccessed }) => {
  const data = useStaticQuery(query);

  return (
    <section className="dashboard-resources-container">
      <h2 className="dashboard-resources-container__heading">{getResourceText('dashboardResourcesHeading')}</h2>
      {lastResourceAccessed ? <LastResourceAccessed data={lastResourceAccessed} /> : null}
      <div className="dashboard-resources">
        {resources.map(({ resourceName, resourceId }, index) => (
          <Link to={`/teacher-resources/${resourceId}`} key={resourceName} className="dashboard-resource">
            <GatsbyImage
              className={`dashboard-resource__image-${(index + 1) % 6}`}
              image={getImage(data[`dashboardResourcesImage${(index + 1) % 6}`])}
              alt={dashboardResourcesImageAltText[(index + 1) % 6]}
            />
            <span className="dashboard-resource__name">{resourceName}</span>
          </Link>
        ))}
      </div>
    </section>
  );
};

const LastResourceAccessed = ({ data }) => {
  const { resource, section, week } = data || {};
  let path;
  let text;

  if (resource) {
    path = `/teacher-resources/${resource.resourceId}`;
    text = resource.resourceName;
    if (section) {
      path = `${path}/sections/${section.resourceId}`;
      text = `${text} / ${section.resourceName}`;
      if (week) {
        path = `${path}/weeks/${week.resourceId}`;
        text = `${text} / ${week.resourceName}`;
      }
    }
  }

  return path ? (
    <Link to={path} className="dashboard-resources-last-resource-accessed">
      <span>{getResourceText('continueWithResource')}</span>
      <span>{text}</span>
    </Link>
  ) : null;
};

const Dashboard = () => {
  const [resources, setResources] = useState();
  const [lastResourceAccessed, setLastResourceAccessed] = useState();
  const [error, setError] = useState(false);

  const { getResourcePacks, getUser } = useApi();

  useEffect(() => {
    const processSystemAdmin = async () => {
      const user = getData(USER_KEY);
      if (user?.isSystemAdmin) {
        await navigate('/admin');
      }
    };
    processSystemAdmin().catch(() => {});
  }, []);

  useEffect(() => {
    const getData = async () => {
      const [{ status, json }, { status: userStatus, json: userData }] = await Promise.all([
        getResourcePacks(),
        getUser(),
      ]);

      if (status === 401) {
        await navigate('/login');
      } else if (status === 200) {
        const { resources } = json;
        setResources([...resources].sort((a, b) => a?.resourceName?.localeCompare(b?.resourceName)));

        if (userStatus === 200) {
          setLastResourceAccessed(userData.lastResourceAccessed);
        }
      } else {
        setError(true);
        // need to handle this below
      }
    };
    getData().catch(() => setError(true));
  }, [getResourcePacks, getUser]);

  return (
    <UserPage title={`${getResourceText('dashboardHeading')}${getData(USER_KEY)?.username ?? ''}`}>
      <div className="dashboard-container">
        {!resources && !error ? (
          <DashboardLoading />
        ) : (
          <DashboardResourcesContainer resources={resources} lastResourceAccessed={lastResourceAccessed} />
        )}
        {error ? <DashboardError /> : null}
      </div>
    </UserPage>
  );
};

export const query = graphql`
  query dashboardPageQuery {
    dashboardResourcesImage1: file(relativePath: { eq: "peri-with-maraccas.png" }) {
      childImageSharp {
        gatsbyImageData(width: 340)
      }
    }
    dashboardResourcesImage2: file(relativePath: { eq: "handel-with-tamborine.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    dashboardResourcesImage3: file(relativePath: { eq: "francesca-with-lute.png" }) {
      childImageSharp {
        gatsbyImageData(width: 280)
      }
    }
    dashboardResourcesImage4: file(relativePath: { eq: "mozart-with-microphone.png" }) {
      childImageSharp {
        gatsbyImageData(width: 320)
      }
    }
    dashboardResourcesImage5: file(relativePath: { eq: "beethoven-with-keyboard.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    dashboardResourcesImage0: file(relativePath: { eq: "wagner-with-french-horn.png" }) {
      childImageSharp {
        gatsbyImageData(width: 280)
      }
    }
    dashboardBottomImage: file(relativePath: { eq: "all-composers.png" }) {
      childImageSharp {
        gatsbyImageData(width: 200)
      }
    }
  }
`;

export default Dashboard;
