import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useApi } from '../../../hooks/api';
import AdminPage from '../../../components/adminPage';
import { getResourceText } from '../../../lib/language';
import LabelInput from '../../../components/labelInput';
import LabelCheckboxInput from '../../../components/labelCheckboxInput';
import LabelSelect from '../../../components/labelSelect';
import PageLoading from '../../../components/pageLoading';
import ErrorHeading from '../../../components/errorHeading';

const isEmpty = (val) => !val || (val && `${val}`.length === 0);

const AdminUsers = () => {
  const [userId, setUserId] = useState();
  const [userIdError, setUserIdError] = useState(false);
  const [userIdErrorCode, setUserIdErrorCode] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [organisations, setOrganisations] = useState('');
  const [organisationsError, setOrganisationsError] = useState(false);

  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState(false);

  const [isActive, setIsActive] = useState();
  const [isSystemAdmin, setIsSystemAdmin] = useState();
  const [credentials, setCredentials] = useState();

  // const [error, setError] = useState(false);
  const [apiError, setApiError] = useState(false);

  const { admin: { postUser, getOrganisations } } = useApi();

  useEffect(() => {
    const getData = async () => {
      const { status, json: organisationsData } = await getOrganisations();

      if (status === 200) {
        setOrganisations([{ label: '-' }, ...organisationsData.map(({ resourceName, resourceId }) => ({ label: resourceName, value: resourceId }))]);
      } else {
        setOrganisationsError(true);
      }
    };
    getData().catch(() => {
      setOrganisationsError(true);
    });
  }, [getOrganisations]);

  const validate = () => {
    const isUserIdError = isEmpty(userId) || !userId.match(/[A-Za-z0-9]+/);
    setUserIdError(isUserIdError);

    const isEmailError = !isEmpty(email) && !email.match(/.+?@.+?\..+?/);
    setEmailError(isEmailError);

    return !isUserIdError && !isEmailError;
  };

  const resetValidation = () => {
    setApiError(false);
    setUserIdError(false);
    setEmailError(false);
  };

  const createUser = async (event) => {
    event.preventDefault();
    resetValidation();

    if (validate()) {
      const { status, json: { credentials, errors } } = await postUser({ userId, email, isActive, isSystemAdmin, organisationId: organisation });

      if (status === 201) {
        setCredentials(credentials);
      } else {
        setApiError(true);

        if (errors?.find(({ text }) => text === 'duplicate user id')) {
          setUserIdError(true);
          setUserIdErrorCode('userIdDuplicateError');
        }
      }
    }
  };

  return (
    <AdminPage withBack>
      <div className="admin-create-user-container">
        <h1 className="admin-create-user-container__main-heading">Users</h1>
        <h2 className="admin-create-user-container__sub-heading">{userId}</h2>
        {!organisations && !organisationsError ? <PageLoading/> : null}
        {organisationsError ? <ErrorHeading text="Error getting organisations"/> : null}
        {!organisationsError && organisations ? (
          <div className="admin-create-user-container__body-container">
            <form onSubmit={(event) => createUser(event)}>
              <LabelInput labelKey="userId" value={userId} onChange={(val) => setUserId(val)} error={userIdError} classNamePrefix="admin-create-user-container"
                          errorCode={userIdErrorCode}/>
              <LabelInput labelKey="email" value={email} onChange={(val) => setEmail(val)} error={emailError} classNamePrefix="admin-create-user-container"/>
              <LabelSelect labelKey="organisation" onChange={setOrganisation} options={organisations} value={organisation} classNamePrefix="admin-create-user-container"/>
              <LabelCheckboxInput labelKey="isActive" checked={isActive} onChange={(val) => setIsActive(val)} classNamePrefix="admin-create-user-container"/>
              <LabelCheckboxInput labelKey="isSystemAdmin" checked={isSystemAdmin} onChange={(val) => setIsSystemAdmin(val)} classNamePrefix="admin-create-user-container"/>
              {credentials ? <LabelInput labelKey="password" value={credentials} readOnly withCopyButton classNamePrefix="admin-create-user-container" /> : null}

              <div className="admin-create-user-container__actions">
                {apiError ? <span
                  className="admin-create-user-container__action-error">{getResourceText('createUserApiError')}</span> : null}
                <button type="button" className="btn admin-btn admin-create-user-container__action"
                        onClick={async () => await navigate(`/admin/users`)}>{getResourceText('cancel')}</button>
                <button type="submit"
                        className="btn admin-btn admin-create-user-container__action">{getResourceText('createUser')}</button>
              </div>
            </form>
          </div>
        ) : null}
      </div>
    </AdminPage>
  );
};

export default AdminUsers;