import React, { useState } from 'react';
import { navigate } from 'gatsby';
import AdminPage from '../../../components/adminPage';
import { getResourceText } from '../../../lib/language';
import { LoadingSpinnerButton } from '../../../components/loading';
import { useApi } from '../../../hooks/api';
import LabelInput from '../../../components/labelInput';

const URL_PATTERN = new RegExp('https://');

const CreateResourcePack = () => {
  const [resourcePackName, setResourcePackName] = useState('');
  const [teacherNotesURL, setTeacherNotesURL] = useState('');
  const [resourcePackNameError, setResourcePackNameError] = useState(false);
  const [teacherNotesURLError, setTeacherNotesURLError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { admin: { createResourcePack: apiCreateResourcePack } } = useApi();

  const validate = () => {
    let isValid = true;
    if (!resourcePackName || !resourcePackName.trim()) {
      setResourcePackNameError(true);
      isValid = false;
    } else {
      setResourcePackNameError(false);
    }

    if(teacherNotesURL && !URL_PATTERN.test(teacherNotesURL)){
      setTeacherNotesURLError(true);
      isValid = false;
    } else {
      setTeacherNotesURLError(false);
    }

    return isValid;
  };

  const createResourcePack = async (event) => {
    event.preventDefault();
    setApiError(false);
    const isValid = validate();

    if (isValid) {
      setLoading(true);

      const { status } = await apiCreateResourcePack({ resourceName: resourcePackName, teacherNotesURL });

      if (status === 201) {
        await navigate('/admin/resource-packs');
      } else {
        setApiError(true);
      }

      setLoading(false);
    }
    return false;
  };
  return (
    <AdminPage withBack>
      <div className="admin-create-resource-pack-container">
        <h2 className="admin-create-resource-pack-container__heading">
          <span className="admin-create-resource-pack-container__heading-text">
            {getResourceText('createResourcePack')}
          </span>
          {loading ? <LoadingSpinnerButton/> : null}
        </h2>
        <div className="admin-create-resource-pack-container__body-container">
          <form onSubmit={(event) => createResourcePack(event)}>
            <LabelInput value={resourcePackName} onChange={setResourcePackName} labelKey="name" classNamePrefix="admin-create-resource-pack-container" error={resourcePackNameError} />
            <LabelInput value={teacherNotesURL} onChange={setTeacherNotesURL} labelKey="teacherNotesURL" classNamePrefix="admin-create-resource-pack-container" error={teacherNotesURLError} />
            <div className="admin-create-resource-pack-container__actions">
              {apiError ? <span
                className="admin-create-resource-pack-container__action-error">{getResourceText('createResourcePackApiError')}</span> : null}
              <button type="button" className="btn admin-btn admin-create-resource-pack-container__action"
                      onClick={async () => await navigate('/admin/resource-packs')}>{getResourceText('cancel')}</button>
              <button type="submit"
                      className="btn admin-btn admin-create-resource-pack-container__action">{getResourceText('createResourcePack')}</button>
            </div>
          </form>
        </div>
      </div>
    </AdminPage>
  );
};

export default CreateResourcePack;