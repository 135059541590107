import { isBrowser, saveData } from './store';
import { useSiteMetadata } from './config';

export const useIFrame = () => {
  const { abcWebsiteUrl: websiteUrl } = useSiteMetadata();

  if (isBrowser) {
    if (window.top !== window) {
      window.onmessage = (event) => {
        if (event.origin === websiteUrl) {
          try {
            const { language, parentHeight } = JSON.parse(event.data);

            if (parentHeight) {
              console.log('parentHeight', parentHeight);
              saveData('parentHeight', parentHeight);
            } else {
              if (language) {
                saveData('language', language);
              } else {
                saveData('language', '');
              }
            }
          } catch (e) {
            console.error('Error communicating with parent iframe', e);
          }
        }
      };
    }

    if (window.top && window.top !== window) {
      window.top.postMessage('language', websiteUrl);
    }

    const getParentHeight = () => {
      if (window.top && window.top !== window) {
        window.top.postMessage('parentHeight', websiteUrl);
      } else {
        saveData('parentHeight', window.outerHeight);
      }
    };
    return { getParentHeight };
  }
  return {};
};
