import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { addDays, addMonths, isBefore, isEqual } from 'date-fns';
import AdminPage from '../../../components/adminPage';
import { getResourceText } from '../../../lib/language';
import { LoadingSpinnerButton } from '../../../components/loading';
import { useApi } from '../../../hooks/api';
import LabelSelect from '../../../components/labelSelect';
import DateInput from '../../../components/dateInput';
import { formatDate } from '../../../lib/date';

const AdminCreateSubscription = () => {
  const [organisations, setOrganisations] = useState();
  const [organisation, setOrganisation] = useState();
  const [organisationError, setOrganisationError] = useState(false);
  const [resourcePacks, setResourcePacks] = useState();
  const [resourcePack, setResourcePack] = useState();
  const [resourcePackError, setResourcePackError] = useState(false);
  const [startDate, setStartDate] = useState(formatDate(new Date()));
  const [startDateError, setStartDateError] = useState(false);
  const [endDate, setEndDate] = useState();
  const [endDateError, setEndDateError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);

  const { admin: { createSubscription: apiCreateSubscription, getOrganisations, getResourcePacks } } = useApi();

  useEffect(() => {
    const getData = async () => {
      const [
        { status: organisationStatus, json: organisationsData },
        { status: resourcePackStatus, json: resourcePackData }
      ] = await Promise.all([getOrganisations(), getResourcePacks()]);

      if (organisationStatus === 200 && resourcePackStatus === 200) {
        setOrganisations([{ label: '-' }, ...organisationsData.map(({ resourceName, resourceId }) => ({ label: resourceName, value: resourceId }))]);
        setResourcePacks([{ label: '-' }, ...resourcePackData.map(({ resourceName, resourceId }) => ({ label: resourceName, value: resourceId }))]);
      } else {
        setError(true);
      }
    };
    getData().catch(() => {
      setError(true);
    });
  }, [getOrganisations, getResourcePacks]);

  const validate = () => {
    let isValid = true;
    setOrganisationError(!organisation);
    setResourcePackError(!resourcePack);
    setStartDateError(!startDate)
    setEndDateError(!endDate);

    if (isBefore(new Date(endDate), new Date(startDate)) || isEqual(new Date(startDate), new Date(endDate))) {
      setStartDateError(true);
      setEndDateError(true);
      isValid = false;
    }
    return isValid && !!organisation && !!resourcePack && !!startDate && !!endDate;
  };

  const resetValidation = () => {
    setOrganisationError(false);
    setResourcePackError(false);
    setStartDateError(false);
    setEndDateError(false);
  };

  const createSubscription = async (event) => {
    event.preventDefault();
    resetValidation();
    setApiError(false);

    if (validate()) {
      setLoading(true);

      const { status } = await apiCreateSubscription({ organisationId: organisation, resourcePackId: resourcePack, startDate, endDate });

      if (status === 201) {
        await navigate('/admin/subscriptions');
      } else {
        setApiError(true);
      }
      setLoading(false);
    }
    return false;
  };

  const updateEndDateMonths = (months) => {
    setEndDate(formatDate(addMonths(new Date(startDate), months)));
    return false;
  }

  return (
    <AdminPage withBack>
      <div className="admin-create-subscription-container">
        <h2 className="admin-create-subscription-container__main-heading">
          <span className="admin-create-subscription-container__heading-text">
            {getResourceText('createSubscription')}
          </span>
          {loading ? <LoadingSpinnerButton/> : null}
        </h2>
        <div className="admin-create-subscription-container__body-container">
          <form onSubmit={(event) => createSubscription(event)}>
            <LabelSelect labelKey="organisation" error={organisationError} onChange={setOrganisation} options={organisations} value={organisation}
                         classNamePrefix="admin-create-subscription-container"/>
            <LabelSelect labelKey="resourcePack" error={resourcePackError} onChange={setResourcePack} options={resourcePacks} value={resourcePack}
                         classNamePrefix="admin-create-subscription-container"/>
            <DateInput labelKey="startDate" min={formatDate(new Date())} error={startDateError} onChange={setStartDate} value={startDate}
                       classNamePrefix="admin-create-subscription-container"/>
            <DateInput labelKey="expiryDate" min={formatDate(addDays(new Date(), 1))} error={endDateError} onChange={setEndDate} value={endDate}
                       classNamePrefix="admin-create-subscription-container"/>
            <div className="admin-create-subscription-container__buttons-container">
              <button type="button" className="btn admin-btn admin-create-subscription-container__quick-date-button" onClick={() => updateEndDateMonths(3)}>{getResourceText('threeMonths')}</button>
              <button type="button" className="btn admin-btn admin-create-subscription-container__quick-date-button" onClick={() => updateEndDateMonths(6)}>{getResourceText('sixMonths')}</button>
              <button type="button" className="btn admin-btn admin-create-subscription-container__quick-date-button" onClick={() => updateEndDateMonths(12)}>{getResourceText('twelveMonths')}</button>
            </div>
            <div className="admin-create-subscription-container__actions">
              {apiError ? <span
                className="admin-create-subscription-container__action-error">{getResourceText('createSubscriptionApiError')}</span> : null}
              <button type="button" className="btn admin-btn admin-create-subscription-container__action"
                      onClick={async () => await navigate('/admin/subscriptions')}>{getResourceText('cancel')}</button>
              <button type="submit"
                      className="btn admin-btn admin-create-subscription-container__action">{getResourceText('createSubscription')}</button>
            </div>
          </form>
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminCreateSubscription;