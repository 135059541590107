import React from 'react';
import { getResourceText } from '../lib/language';

const LabelCheckboxInput = ({ labelKey, onChange, checked = false, error = false, classNamePrefix = '' }) => {
  const id = labelKey;
  return (
    <div className={`${classNamePrefix}__label-inputs-container`}>
      <div className={`${classNamePrefix}__label-input-container`}>
        <label className={`${classNamePrefix}__label`} htmlFor={id}>{getResourceText(labelKey)}</label>
        <div className={`${classNamePrefix}__input-container ${classNamePrefix}__checkbox-container`}>
          <input id={id} type="checkbox" checked={checked} className={`${classNamePrefix}__checkbox_input`}
                 onChange={() => onChange(!checked)}/>
        </div>
      </div>
      {error ? <span className={`${classNamePrefix}__error`}>{getResourceText(`${labelKey}Error`)}</span> : null}
    </div>
  );
};

export default LabelCheckboxInput;