import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import AdminPage from '../../../../../components/adminPage';
import { getResourceText } from '../../../../../lib/language';
import { LoadingSpinnerButton } from '../../../../../components/loading';
import { useApi } from '../../../../../hooks/api';
import { googleSlidesFormat } from '../../../../../lib/url';
import LabelInput from '../../../../../components/labelInput';

const AdminCreateLesson = ({ resourcePackId: resourceId, location }) => {
  const [lessonName, setLessonName] = useState('');
  const [googleSlidesURL, setGoogleSlidesURL] = useState('');
  const [youtubeURL, setYoutubeURL] = useState('');

  const [resourcePack, setResourcePack] = useState();
  const [lessonNameError, setLessonNameError] = useState(false);
  const [googleSlidesOrYoutubeError, setGoogleSlidesOrYoutubeError] = useState(false);
  const [googleSlidesFormatError, setGoogleSlidesFormatError] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setError] = useState(false);

  const getSearchParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      sectionId: params.get('sectionId'),
      weekId: params.get('weekId')
    };
  };

  const { admin: { createLesson: apiCreateLesson, getResourcePack } } = useApi();

  useEffect(() => {
    if (resourceId) {
      const getData = async () => {
        const { status, json } = await getResourcePack(resourceId);

        if (status === 200) {
          setResourcePack(json);
        } else {
          setError(true);
        }
      };

      getData().catch(() => setError(true));
    }
  }, [getResourcePack, resourceId]);

  const validate = () => {
    if (!lessonName) {
      setLessonNameError(true);
    } else {
      setLessonNameError(false);
    }

    if ((!googleSlidesURL && !youtubeURL) || (googleSlidesURL && youtubeURL)) {
      setGoogleSlidesOrYoutubeError(true);
    } else {
      setGoogleSlidesOrYoutubeError(false);
    }

    if (googleSlidesURL && !googleSlidesFormat.exec(googleSlidesURL)) {
      setGoogleSlidesFormatError(true);
    } else {
      setGoogleSlidesFormatError(false);
    }
  };

  const isValid = () => {
    if (!!lessonName?.trim() && ((googleSlidesURL || youtubeURL) && !(googleSlidesURL && youtubeURL))) {
      return !(googleSlidesURL && !googleSlidesFormat.exec(googleSlidesURL));
    }
    return false;
  };

  const generateAddLessonUrl = () => {
    const { sectionId, weekId } = getSearchParams();
    let url = `/admin/resource-packs/${resourceId}`;

    if (sectionId) {
      url = `${url}/sections/${sectionId}`;
    }

    if (weekId) {
      url = `${url}/weeks/${weekId}`;
    }

    return `${url}/lessons/add`;
  };

  const createLesson = async (event) => {
    event.preventDefault();
    setApiError(false);
    validate();

    if (isValid()) {
      const formattedGoogleSlidesUrl = googleSlidesURL ? googleSlidesFormat.exec(googleSlidesURL)[0] : googleSlidesURL;

      setLoading(true);

      const { status } = await apiCreateLesson({ resourceId, lessonName, googleSlidesURL: formattedGoogleSlidesUrl, youtubeURL });

      if (status === 201) {
        await navigate(generateAddLessonUrl());
      } else {
        setApiError(true);
      }

      setLoading(false);
    }
    return false;
  };
  return (
    <AdminPage withBack>
      <h2 className="admin-create-lesson-heading">{resourcePack?.resourceName}</h2>
      <div className="admin-create-lesson-container">
        <h2 className="admin-create-lesson-container__heading">
          <span className="admin-create-lesson-container__heading-text">
            {getResourceText('createLesson')}
          </span>
          {loading ? <LoadingSpinnerButton/> : null}
        </h2>
        <div className="admin-create-lesson-container__body-container">
          <form onSubmit={(event) => createLesson(event)}>
            <LabelInput value={lessonName} labelKey="name" error={lessonNameError} onChange={setLessonName} classNamePrefix="admin-create-lesson-container" />
            <LabelInput value={googleSlidesURL} labelKey="googleSlidesURL" error={googleSlidesOrYoutubeError || googleSlidesFormatError} errorCode={googleSlidesFormatError ? 'googleSlidesFormatError' : 'googleSlidesOrYouTubeError'} onChange={setGoogleSlidesURL} classNamePrefix="admin-create-lesson-container" />
            <LabelInput value={youtubeURL} labelKey="youtubeURL" error={googleSlidesOrYoutubeError} onChange={setYoutubeURL} classNamePrefix="admin-create-lesson-container" />
            <div className="admin-create-lesson-container__actions">
              {apiError ? <span
                className="admin-create-lesson-container__action-error">{getResourceText('createLessonApiError')}</span> : null}
              <button type="button" className="btn admin-btn admin-create-lesson-container__action"
                      onClick={async () => await navigate(`/admin/resource-packs/${resourceId}/lessons/edit`)}>{getResourceText('cancel')}</button>
              <button type="submit"
                      className="btn admin-btn admin-create-lesson-container__action">{getResourceText('createLesson')}</button>
            </div>
          </form>
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminCreateLesson;