import React, { Fragment, memo, useEffect, useState } from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import { getResourceText } from '../../../../lib/language';
import Loading, { LoadingSpinner, LoadingText } from '../../../../components/loading';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import UserPage from '../../../../components/userPage';
import { useApi } from '../../../../hooks/api';
import { sortResourcesByName } from '../../../../lib/sort';
import TeacherNotes from '../../../../components/teacherNotes';

const weeksResourcesImageAltText = [
  'Wagner',
  'Luckless Lully',
  'Handel',
  'Chevalier',
  'Mozart',
  'Rossini',
  'Francesca',
];

const WeekLinks = memo(({ resourceId, sectionId, weeks }) => {
  const data = useStaticQuery(query);

  return (
    <div className="week-links-container">
      {weeks.filter(Boolean).map(({ resourceName, resourceId: weekId }, index) => (
        <Link
          to={`/teacher-resources/${resourceId}/sections/${sectionId}/weeks/${weekId}`}
          key={resourceName}
          className={`week-resource week-resource_${index % 4}`}
        >
          <GatsbyImage
            className={`week-resource__image-${(index + 1) % 7}`}
            image={getImage(data[`weeksResourcesImage${(index + 1) % 7}`])}
            alt={weeksResourcesImageAltText[(index + 1) % 7]}
          />
          <span className="week-resource__name">{resourceName}</span>
        </Link>
      ))}
    </div>
  );
});

const getAllWeeks = async ({ fetchFunction, resourceId, sectionId, weekIds }) => {
  return await Promise.all(
    weekIds.map(async (weekId) => {
      const { status, json } = await fetchFunction({ resourceId, sectionId, weekId });

      if (status === 200) {
        return json;
      }
    })
  );
};

const ResourceError = memo(() => (
  <div className="weeks-container__no-resources">
    <h2 className="weeks-container__sub-heading">{getResourceText('sectionError')}</h2>
    <p>{getResourceText('sectionErrorText')}</p>
  </div>
));

const ResourceLoading = memo(() => (
  <div className="weeks-container__loading">
    <Loading>
      <LoadingSpinner />
      <LoadingText>{getResourceText('resourceSectionsLoading')}</LoadingText>
    </Loading>
  </div>
));

const ResourceSection = ({ resourceId, sectionId }) => {
  const [weeks, setWeeks] = useState();
  const [section, setSection] = useState();
  const [resource, setResource] = useState();
  const [error, setError] = useState(false);
  const { getResourcePack, getSection, getWeek } = useApi();

  useEffect(() => {
    if (resourceId && sectionId) {
      // get resource packs allowed for user
      const getData = async () => {
        const [{ status: resourceStatus, json: resourceData }, { status: sectionStatus, json: sectionData }] =
          await Promise.all([getResourcePack(resourceId), getSection({ resourceId, sectionId })]);

        if (resourceStatus === 200 && sectionStatus === 200) {
          setResource(resourceData);
          const { weeks: weekIds = [], ...rest } = sectionData;
          setSection({ ...rest, weeks: weekIds });

          const weeks = await getAllWeeks({ fetchFunction: getWeek, resourceId, sectionId, weekIds });
          setWeeks(sortResourcesByName(weeks));
        } else {
          setError(true);
        }
      };
      getData().catch(() => setError(true));
    }
  }, [resourceId, sectionId, getResourcePack, getSection, getWeek]);

  return (
    <UserPage title={resource && section ? `${resource?.resourceName} / ${section?.resourceName}` : null}>
      <div className="weeks-container">
        {resource && section ? (
          <Fragment>
            <div className="section-container__heading-links">
              <Link to="/dashboard" className="weeks-container__heading-link">
                {getResourceText('backToDashboardLinkText')}
              </Link>{' '}
              /
              <Link to={`/teacher-resources/${resourceId}`} className="weeks-container__heading-link">
                {resource?.resourceName}
              </Link>
            </div>
            <TeacherNotes
              sectionNotesURL={section?.teacherNotesURL}
              teacherNotesURL={resource?.teacherNotesURL}
              resourcePackName={resource?.resourceName}
              sectionName={section?.resourceName}
            />
            {weeks ? <WeekLinks sectionId={sectionId} resourceId={resourceId} weeks={weeks} /> : null}
          </Fragment>
        ) : null}
        {!weeks && !error ? <ResourceLoading /> : null}
        {error ? <ResourceError /> : null}
      </div>
    </UserPage>
  );
};

export const query = graphql`
  query resourceWeeksPageQuery {
    weeksResourcesImage1: file(relativePath: { eq: "lully-with-accordion.png" }) {
      childImageSharp {
        gatsbyImageData(width: 250)
      }
    }
    weeksResourcesImage2: file(relativePath: { eq: "handel-with-tamborine.png" }) {
      childImageSharp {
        gatsbyImageData(width: 340)
      }
    }
    weeksResourcesImage3: file(relativePath: { eq: "chevalier-with-violin.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    weeksResourcesImage4: file(relativePath: { eq: "mozart-with-microphone.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    }
    weeksResourcesImage5: file(relativePath: { eq: "rossini-with-bongos.png" }) {
      childImageSharp {
        gatsbyImageData(width: 260)
      }
    }
    weeksResourcesImage6: file(relativePath: { eq: "francesca-with-lute.png" }) {
      childImageSharp {
        gatsbyImageData(width: 280)
      }
    }
    weeksResourcesImage0: file(relativePath: { eq: "wagner-with-french-horn.png" }) {
      childImageSharp {
        gatsbyImageData(width: 280)
      }
    }
  }
`;

export default ResourceSection;
