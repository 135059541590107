import React from 'react';
import AdminPage from '../../components/adminPage';

const AdminUsers = () => {
  return (
    <AdminPage>
      <div className="admin-users-container">
        <h1 className="admin-users-container__main-heading">Users</h1>
      </div>
    </AdminPage>
  );
};

export default AdminUsers;