import React, { useEffect, useMemo, useState } from 'react';
import { Link, navigate } from 'gatsby';
import classNames from 'classnames';
import AdminPage from './adminPage';
import { getResourceText } from '../lib/language';
import Loading, { LoadingSpinner, LoadingSpinnerButton, LoadingText } from './loading';
import { useApi } from '../hooks/api';
import LessonView from './lessonView';

const Lesson = ({
  resourceId,
  sectionId,
  weekId,
  selectedLessonId,
  setSelectedLessonId,
  withAdd,
  lesson,
}) => {
  //const [lesson, setLesson] = useState();
  const [selected, setSelected] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [error, setError] = useState(false);
  const {
    admin: { updateSection, updateWeek },
  } = useApi();

  useEffect(() => {
    if (selectedLessonId && selectedLessonId !== lesson.resourceId) {
      setSelected(false);
    }
  }, [selectedLessonId, lesson.resourceId]);

  const toggleSelected = () => {
    setSelected(!selected);
    setSelectedLessonId(lesson.resourceId);
  };

  const addLesson = async () => {
    setAddLoading(true);

    const apiUpdate = async () => {
      if (sectionId && weekId) {
        return await updateWeek({ resourceId, sectionId, weekId, lessonsToAdd: [lesson.resourceId] });
      } else if (sectionId && !weekId) {
        return await updateSection({ resourceId, sectionId, lessonsToAdd: [lesson.resourceId] });
      }
    };

    const { status } = await apiUpdate();

    if (status === 200) {
      let hash;

      if (weekId) {
        hash = `#week-${weekId}`;
      } else if (sectionId) {
        hash = `#section-${sectionId}`;
      }

      await navigate(`/admin/resource-packs/${resourceId}/edit${hash}`);
    } else {
      // deal with this below, lesson has not been added correctly
      setAddLoading(false);
      setError(true);
    }
  };

  // might be better as a button
  return (
    <div
      className={classNames('admin-add-lesson-container__lesson admin-add-lesson-container__lesson--selectable', {
        'admin-add-lesson-container__lesson--selected': selected,
      })}
      onClick={() => (lesson ? toggleSelected() : null)}
      role="button"
    >
      {!lesson && !error ? <LoadingSpinner /> : null}
      {lesson ? (
        <>
          <h3 className="admin-add-lesson-container__lesson-heading">{lesson.resourceName}</h3>
          <div className="admin-add-lesson-container__lesson-resource-container">
            <LessonView googleSlidesURL={lesson.googleSlidesURL} youtubeURL={lesson.youtubeURL} />
          </div>
          {selected ? (
            <div className="admin-add-lesson-container__lesson-actions-container">
              <Link
                to={`/admin/resource-packs/${resourceId}/lessons/${lesson.resourceId}/edit`} // add query string to this bit
                className="btn admin-btn admin-add-lesson-container__lesson-actions-container__action"
              >
                {getResourceText('edit')}
              </Link>
              {withAdd ? (
                <button
                  onClick={() => addLesson()}
                  className="btn admin-btn admin-add-lesson-container__lesson-actions-container__action"
                  disabled={addLoading}
                >
                  {addLoading ? <LoadingSpinnerButton /> : getResourceText('add')}
                </button>
              ) : null}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
};

const Lessons = ({ resourceId, sectionId, weekId, lessonIds, withAdd }) => {
  const [selectedLessonId, setSelectedLessonId] = useState();
  const [lessons, setLessons] = useState();
  const [error, setError] = useState(false);

  const {
    admin: { getLessons },
  } = useApi();

  useEffect(() => {
    getLessons({ resourceId }).then(({ status, json }) => {
      if (status === 200) {
        setLessons(json);
      } else setError(true);
    });
  }, [lessonIds, getLessons, resourceId]);

  return (
    <div
      className={classNames('admin-add-lesson-container__lessons', {
        'admin-add-lesson-container__lessons-loading': !error && !lessons,
      })}
    >
      {lessons
        ? lessons?.map((lesson) => (
            <Lesson
              key={lesson.resourceId}
              resourceId={resourceId}
              sectionId={sectionId}
              weekId={weekId}
              lesson={lesson}
              selectedLessonId={selectedLessonId}
              setSelectedLessonId={setSelectedLessonId}
              withAdd={withAdd}
            />
          ))
        : null}
      {error ? <h2>Error loading lessons</h2> : null}
      {!error && !lessons ? (
        <Loading>
          <LoadingSpinner />
          <LoadingText>Loading...</LoadingText>
        </Loading>
      ) : null}
    </div>
  );
};

const AdminLessonPage = ({ resourceId, sectionId, weekId, withAdd }) => {
  const [resourcePack, setResourcePack] = useState();
  const [, setError] = useState(false);
  const {
    admin: { getResourcePack },
  } = useApi();

  const queryString = useMemo(() => {
    if (!sectionId && !weekId) return '';

    let query = '?';

    if (sectionId) {
      query = `${query}sectionId=${sectionId}`;
    }

    if (weekId) {
      query = `${query}&weekId=${weekId}`;
    }

    return query;
  }, [sectionId, weekId]);

  useEffect(() => {
    if (resourceId) {
      const getData = async () => {
        const { status, json } = await getResourcePack(resourceId);

        if (status === 200) {
          setResourcePack(json);
        } else {
          setError(true);
        }
      };

      getData().catch(() => setError(true));
    }
  }, [resourceId, getResourcePack]);

  return (
    <AdminPage withBack={true}>
      <div className="admin-add-lesson-container">
        <h2 className="admin-add-lesson-container__main-heading">{resourcePack?.resourceName}</h2>
        <h3 className="admin-add-lesson-container__sub-heading">{getResourceText('lessons')}</h3>
        <div className="admin-add-lesson-container__body-container">
          {resourcePack && !resourcePack?.lessons?.length ? <p>{getResourceText('noLessonsInResourcePack')}</p> : null}
          {resourcePack && resourcePack?.lessons?.length ? (
            <Lessons
              resourceId={resourceId}
              sectionId={sectionId}
              weekId={weekId}
              lessonIds={resourcePack.lessons}
              withAdd={withAdd}
            />
          ) : null}
          <div className="admin-add-lesson-container__actions-container">
            <Link to={`/admin/resource-packs/${resourceId}/edit`} className="btn admin-btn admin-add-lesson-container__actions-container-action">
              {getResourceText('backToResourcePack')}
            </Link>
            <Link to={`/admin/resource-packs/${resourceId}/lessons/create${queryString}`} className="btn admin-btn">
              {getResourceText('createLesson')}
            </Link>
          </div>
        </div>
      </div>
    </AdminPage>
  );
};

export default AdminLessonPage;
